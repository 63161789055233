import { h, JSX } from 'preact';
import { standardSvgAttrs } from '../../../../../../../utilities/svg_boilerplate.js';

export const RoundedShareButton = (): JSX.Element => {
  const svgAttrs = standardSvgAttrs({
    width: 40,
    height: 34,
    ariaHidden: true,
    styleOverride: { fill: 'none' },
  }) as JSX.SVGAttributes<SVGSVGElement>;

  const stroke = {
    stroke: '#fff',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round',
  } as JSX.SVGAttributes<SVGSVGElement>;

  return (
    <svg {...svgAttrs}>
      <g {...stroke}>
        <path d="M18 11H13C11.8954 11 11 11.8954 11 13V21C11 22.1046 11.8954 23 13 23H28C29.1046 23 30 22.1046 30 21V17" />
        <path d="M26.9452 14.6361L29.6179 9.29069L24.2725 7.47705" />
        <path d="M29.0001 9.49991C22.6046 11.3135 20.0001 13.559 20.0001 18.9999" />
      </g>
    </svg>
  );
};
