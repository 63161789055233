import { Wistia } from 'wistia_namespace.js';
import '../../controls/ShareControl/ShareControl.js';
import { doTimeout } from 'utilities/timeout-utils.js';

class SharePlugin extends Wistia.Plugin.Base {
  constructor(video, options) {
    super(video, options);
    this.pluginName = 'share-v2';
    this.video = video;
    this.options = options;
    this._isEnabled = options.on !== false;
    this._origImpl = video._impl;
    doTimeout(
      `${video.uuid}.enable_or_disable_share_controls`,
      () => {
        if (this._isEnabled && this.options.channels) {
          this.enable();
        } else {
          this.disable();
        }
      },
      1,
    );
  }

  enable() {
    return this.video._impl.setControlEnabled('shareButton', true);
  }

  disable() {
    this.video.setControlEnabled('shareButton', false);
  }

  update(newOptions, shouldShowDialog) {
    if (newOptions.on === false) {
      this.disable();
      return;
    }
    if (newOptions.channels) {
      const enablePromise = this.enable().then(() => {
        if (shouldShowDialog) {
          this.video.controls.shareButton.dialog.open();
        }
        this.video.controls.shareButton.updateFromOptionsAndRender(newOptions);
      });
      this.video.requestControls('changed_share', 4000);
      clearTimeout(this._closeShareTimeout);
      this._closeShareTimeout = setTimeout(() => {
        this.video?.controls?.shareButton?.dialog?.close();
      }, 4000);
      return enablePromise;
    }
    return this.disable();
  }

  customizePreview(changeSet) {
    if (!changeSet.anyChanged(['plugin[share-v2]', 'plugin[share]'])) {
      return;
    }

    const isShareOpen = changeSet.currentValue('ephemeral[ui][share][isOpen]');
    const shareOptions = changeSet.currentValue('plugin[share-v2]') ||
      changeSet.currentValue('plugin[share]') || { on: false };

    return this.update(shareOptions, isShareOpen);
  }

  remove() {
    if (this.video._impl === this._origImpl) {
      this.disable();
    }
    super.remove();
  }
}

Wistia.plugin('share-v2', (video, options) => {
  return new SharePlugin(video, options);
});
