import { h, render, Component } from 'preact';
import { standardSvgAttrs } from 'utilities/svg_boilerplate.js';

class ShareButton extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const sharedLineAttrs = {
      fill: 'none',
      stroke: '#ffffff',
      'stroke-width': 2,
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round',
      'stroke-miterlimit': 10,
    };
    return (
      <svg {...standardSvgAttrs({ width: 40, height: 34, ariaHidden: true })}>
        <g {...sharedLineAttrs}>
          <polyline points="18,11.7 9.6,11.7 9.6,25 30.1,25 30.1,17.1" />
          <polyline points="27.8,14 30.6,8.4 25,6.5" />
          <path d="M30.3,8.7c-6.7,1.9-10.9,5.9-10.9,11.6" />
        </g>
      </svg>
    );
  }
}

export default ShareButton;
